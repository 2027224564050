import {Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-standards',
  templateUrl: './standards.component.html',
  styleUrls: ['./standards.component.css']
})
export class StandardsComponent implements OnInit {

  constructor() { 
  }

  ngOnInit() {
  }

}
