<div class="category-home-description" style="display: flex; flex-direction: column;">

    <div id="minimalistic-desktop">
        <h1 id="mtl-title-1">
            <span style="color: black;">MARTIN</span> <span style="color: orange; margin-left: 20;"> TESTING</span><br>
            <span id="mtl-title-2">LABORATORIES</span>
        </h1>
    </div>

    <div id="mobile-homepage">
        <div routerLink="vib-testing"
            style="display: flex; flex-direction: column; align-items: center; text-decoration: none; color: black; width: 100%; padding-bottom: 2em;">
            <img style="border-radius: 50% 50% 0 0; max-width: 235px;" src="../../assets/images/Index/1_Vib_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div class="break"></div>
            <a class="btn btn-primary" style="max-width: 235px; margin-top: 1em;" href="vib-testing"
                role="button">Vibration Testing</a>
        </div>
        <div class="break"></div>
        <div routerLink="mech-testing"
            style="display: flex; flex-direction: column; align-items: center; text-decoration: none; color: black; width: 100%; padding-bottom: 2em;">
            <img style="border-radius: 50% 50% 0 0; max-width: 235px;" src="../../assets/images/Index/1_Mech_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div class="break"></div>
            <a class="btn btn-primary" style="max-width: 235px; margin-top: 1em;" href="mech-testing"
                role="button">Mechanical Testing</a>
        </div>
        <div class="break"></div>
        <div routerLink="shock-testing"
            style="display: flex; flex-direction: column; align-items: center; text-decoration: none; color: black; width: 100%; padding-bottom: 2em;">
            <img style="border-radius: 50% 50% 0 0; max-width: 235px;"
                src="../../assets/images/Index/1_Shock_square.jpg" alt="Vibration Testing" height="auto" />
            <div class="break"></div>
            <a class="btn btn-primary" style="max-width: 235px; margin-top: 1em;" href="shock-testing"
                role="button">Shock Testing</a>
        </div>
        <div class="break"></div>
        <div routerLink="env-testing"
            style="display: flex; flex-direction: column; align-items: center; text-decoration: none; color: black; width: 100%; padding-bottom: 2em;">
            <img style="border-radius: 50% 50% 0 0; max-width: 235px;" src="../../assets/images/Index/1_Env_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div class="break"></div>
            <a class="btn btn-primary" style="max-width: 235px; margin-top: 1em;" href="env-testing"
                role="button">Environmental Testing</a>
        </div>
    </div>

    <div id="desktop-homepage">

        <div routerLink="vib-testing"
            style="display: flex; flex-direction: column; text-decoration: none; color: black; width: 20%; max-width: 235px;">
            <img style="border-radius: 50% 50% 0 0;" src="../../assets/images/Index/1_Vib_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div style="height: 100%; text-align: justify; margin-top: 1em;">
                <span>A founding physics principle states that every object vibrates to some degree. This can be at the
                    micro level, where atomic and sub-atomic particles vibrate at slow or fast rates, or happen at the macro level,
                    where huge objects experience environmental forces causing them to move.
                    It’s fair to state that vibrations are inescapable facts of life, and they’re necessary factors in a
                    fluid world.</span>
            </div>
            <a class="btn btn-primary" style="margin-top: 1em;" href="vib-testing" role="button">Vibration Testing</a>
        </div>

        <div routerLink="mech-testing"
            style="display: flex; flex-direction: column; text-decoration: none; color: black; width: 20%; max-width: 235px;">
            <img style="border-radius: 50% 50% 0 0;" src="../../assets/images/Index/1_Mech_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div style="height: 100%; text-align: justify; margin-top: 1em;">
                <span>Mechanical testing employs a variety of strength tests that can determine the suitability of a
                    material or component for the intended application. It measures characteristics such as elasticity,
                    tensile strength, elongation, hardness, facture toughness, impact resistance, stress fracture, and
                    the fatigue limit.</span>
            </div>
            <a class="btn btn-primary" style="margin-top: 1em;" href="mech-testing" role="button">Mechanical Testing</a>
        </div>

        <div routerLink="shock-testing"
            style="display: flex; flex-direction: column; text-decoration: none; color: black; width: 20%; max-width: 235px;">
            <img style="border-radius: 50% 50% 0 0;" src="../../assets/images/Index/1_Shock_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div style="height: 100%; text-align: justify; margin-top: 1em;">
                <span>Shock testing of products and materials determines to what degree items can physically and
                    functionally withstand relatively infrequent, short time, moderately high-level force impulses or
                    temperature changes that would be encountered in handling, transportation, and service environments.
                    Vibration testing, on the other hand, determines the extent to which items can withstand mechanical
                    vibrations.</span>
            </div>
            <a class="btn btn-primary" style="margin-top: 1em;" href="shock-testing" role="button">Shock Testing</a>
        </div>

        <div routerLink="env-testing"
            style="display: flex; flex-direction: column; text-decoration: none; color: black; width: 20%; max-width: 235px;">
            <img style="border-radius: 50% 50% 0 0;" src="../../assets/images/Index/1_Env_square.jpg"
                alt="Vibration Testing" height="auto" />
            <div style="height: 100%; text-align: justify; margin-top: 1em;">
                <span>Simulated test environments are all about preparing products for real-world use. This is
                    accomplished by gaining as much testing experience and performance data as early as possible during
                    the products’ lifecycles. Simulated environmental testing usually involves creating artificial
                    environments for test items to determine their expected resilience to their anticipated
                    environments.</span>
            </div>
            <a class="btn btn-primary" style="margin-top: 1em;" href="env-testing" role="button">Environmental
                Testing</a>
        </div>

    </div>
</div>