<div class="category-home-description">
    <div>
        <h3 style="text-align: center;">
            <strong>Environmental Testing</strong>
        </h3>
    </div>
    <div>
        <h3 style="padding-bottom: 2em; line-height: 1.6;">
            Environmental testing typically involves hot or cold temperatures, humidity, altitude (vacuum) or thermal shock. This testing can even be combined with other test methods such as vibration or shock.
        </h3>

        <div>
            <div class="accordion p-3" id="accordionThermal">
                <div class="accordion-item">
                    <h3 class="accordion-header" id="headingThermal">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThermal" aria-expanded="false" aria-controls="collapseThermal">
                            <strong>Thermal</strong>
                        </button>
                    </h3>
                    <div id="collapseThermal" class="accordion-collapse collapse" aria-labelledby="headingThermal" data-bs-parent="#accordionThermal">
                        <div class="accordion-body">
                            <h3 style="line-height: 1.6;">
                                The majority of our environmental testing clients need thermal control. We have multiple chambers, each with their own specific capabilities. Temperature can be controlled from -60 ºC to +1100 ºC. Onsite, bulk liquid nitrogen storage (LN2) enables us
                                to offer auxiliary cooling all the way to LN2 bath testing.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion p-3" id="accordionVaccum">
                <div class="accordion-item">
                    <h3 class="accordion-header" id="headingVaccum">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVaccum" aria-expanded="false" aria-controls="collapseVaccum">
                            <strong>Vaccum</strong>
                        </button>
                    </h3>
                    <div id="collapseVaccum" class="accordion-collapse collapse" aria-labelledby="headingVaccum" data-bs-parent="#accordionVaccum">
                        <div class="accordion-body">
                            <h3 style="line-height: 1.6;">
                                In addition to thermal control, some of our chambers enable altitude (vacuum) testing. This combined environment can add simulated altitude up to 100,000 ft. to your thermal test.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion p-3" id="accordionHumidity">
                <div class="accordion-item">
                    <h3 class="accordion-header" id="headingHumidity">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHumidity" aria-expanded="false" aria-controls="collapseHumidity">
                            <strong>Humidity</strong>
                        </button>
                    </h3>
                    <div id="collapseHumidity" class="accordion-collapse collapse" aria-labelledby="headingHumidity" data-bs-parent="#accordionHumidity">
                        <div class="accordion-body">
                            <h3 style="line-height: 1.6;">
                                Some of our environmental chambers can add humidity control to your thermal test. Humidity control is a strong function of temperature but can range from practically zero to almost 100% relative humidity. Condensation can be generated by controlling high
                                humidity and then exposing the test specimen to thermal cooling in the chamber.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion p-3" id="accordionThermalShock">
                <div class="accordion-item">
                    <h3 class="accordion-header" id="headingThermalShock">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThermalShock" aria-expanded="false" aria-controls="collapseThermalShock">
                            <strong>Thermal Shock</strong>
                        </button>
                    </h3>
                    <div id="collapseThermalShock" class="accordion-collapse collapse" aria-labelledby="headingThermalShock" data-bs-parent="#accordionThermalShock">
                        <div class="accordion-body">
                            <h3 style="line-height: 1.6;">
                                Thermal shock involves our elevator chamber. This chamber allows automated cycling from a hot chamber to a cold one. Typical transfer rates from cold to hot are 5 sec and hot to cold of 15 seconds. Auxiliary LN2 cooling enables a rapid response. The total
                                cycle count, dwell time at hot, and dwell time at cold can be programmed.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>