<div class="category-home-description">
    <h1>Contact Us!</h1>
    <div style="display: flex; justify-content: space-between; flex-wrap: wrap; width: 100%;">
        <div id="contact-us-form">
            <!-- Start Form -->
            <form [formGroup]="angForm" (ngSubmit)="submitForm()" novalidate>
                <!-- Name Validator -->
                <div class="form-group">
                    <label>Name:</label>
                    <input class="form-control" formControlName="name" type="text">
                </div>
                <div *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)"
                    class="alert alert-danger">
                    <div *ngIf="angForm.controls['name'].errors.required">
                        Name is required.
                    </div>
                </div>

                <!-- Email Validator -->
                <div class="form-group">
                    <label>Email:</label>
                    <input class="form-control" formControlName="address" type="email">
                </div>
                <div *ngIf="angForm.controls['address'].invalid && (angForm.controls['address'].dirty || angForm.controls['address'].touched)"
                    class="alert alert-danger">
                    <div *ngIf="angForm.controls['address'].errors.required">
                        Email is required.
                    </div>
                </div>

                <!-- Telephone - optional -->
                <div class="form-group">
                    <label>Telephone (optional):</label>
                    <input class="form-control" formControlName="telephone" type="text"
                        placeholder="(###) ###-#### (optional)">
                </div>

                <!-- Message Validator -->
                <div class="form-group">
                    <label>Message:</label>
                    <textarea class="form-control" formControlName="message" type="text"
                        placeholder="Type your message..."></textarea>
                </div>
                <div *ngIf="angForm.controls['message'].invalid && (angForm.controls['message'].dirty || angForm.controls['message'].touched)"
                    class="alert alert-danger">
                    <div *ngIf="angForm.controls['message'].errors.required">
                        Please let us know what you're interested in.
                    </div>
                </div>

                <!-- ReCaptcha Div -->
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"></ngx-recaptcha2>

                <!-- Submit -->
                <button type="submit" [disabled]="angForm.invalid" class="btn btn-primary" style="margin-top: 1em;">
                    Submit</button>

                <!-- End Form -->
            </form>
            <div class="break"></div>
            <div style="padding-top: 2%;">
                <i class="fa fa-phone" style="font-size:24px">&nbsp; Phone: (916) 920-4110</i><br>
                <i class="fa fa-fax" style="font-size:24px">&nbsp;Fax: (916) 920-4390</i><br>
                <i class="fa fa-envelope" style="font-size:24px">&nbsp;Email: info@pfc.io</i><br>
            </div>
        </div>
        <div id="contact-us-break"></div>
        <div id="contact-us-map">
            <div class="gmap_canvas h-100 w-100">
                <iframe width="100%" height="100%" id="gmap_canvas "
                    src="https://maps.google.com/maps?q=4724%20Arnold%20Ave,%20McClellan%20Park,%20CA%2095652&t=&z=17&ie=UTF8&iwloc=&output=embed "
                    frameborder="0 " scrolling="no" marginheight="0" marginwidth="0 "></iframe>
            </div>
        </div>
    </div>
</div>