<h1 hidden>Vibration Testing</h1>

<div class="category-home-description">
    <div>
        <h3 style="text-align: center; padding-bottom: 2em;">
            <strong>Vibration Testing</strong>
        </h3>
    </div>
    <div>
        <h3 style="padding-bottom: 2em;">
            Vibration testing includes kinetic energy transfer to the test specimen, often described as the Device Under
            Test or simply DUT. Typically specified in terms of displacement, velocity, acceleration or the
            corresponding power spectral density units as
            a function of frequency. The form of the vibration may be sinusoidal, random, or a combination.
        </h3>
        <h3 style="padding-bottom: 2em;">
            Martin Testing Laboratories utilizes an Unholtz-Dickie T-1000 electro-dynamic shaker. The Device Under Test
            (DUT) is mounted directly or through a fixture plate to the head of the shaker or onto the slip plate. The
            exact orientation is dependent on several
            things such as the required axis of test, mass, and ease of fixturing.
        </h3>
        <div class="image-vib-center" style="flex-direction: row; justify-content: space-evenly;">
            <h3 style="width: 33%; font-weight: bold; text-align: justify;">Our highly customizable Vibration table is
                compatible with many different configurations. A shaker head and slip plate, for example.</h3>
            <img src="../../assets/images/VibTest/vibtable1.jpg" width="402" height="300" />
        </div>
        <h3>
            Our vibration testing is exclusively carried out closed-loop. By using accelerometers on the shaker and
            optionally on the DUT, we have high confidence knowing exactly what energy we impart on your unit. Our
            controller then compensates in real-time for
            any changes in the entire system ensuring we achieve the specified, nominal test values.
        </h3>

        <div class="accordion p-3" id="accordionExamplee">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOnee">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOnee" aria-expanded="false" aria-controls="collapseOnee">
                        <strong>Swept-Sine</strong>
                    </button>
                </h2>
                <div id="collapseOnee" class="accordion-collapse collapse" aria-labelledby="headingOnee"
                    data-bs-parent="#accordionExamplee">
                    <div class="accordion-body" style="display: flex;">
                        <div class="image-vib-left">
                            <img style="max-width: 100%; height: auto;"
                                src="../../assets/images/VibTest/Sine_MTL_Sample_Page_6.png" alt="" />
                            <h3 style="font-weight: bold; text-align: justify; line-height: 1.6;">For Swept-Sine
                                testing, generally a frequency-varying sinusoidal wave is generated and sent
                                to the shaker. These can be specified by position, velocity, or acceleration across a
                                frequency. We find most clients specify this test using
                                acceleration units of g (acceleration due to gravity) from a start frequency in Hz
                                through
                                an ending frequency in Hz. Stepped_Sine testing is similar to Swept-Sine but usually
                                involves testing at discreet frequencies (through some
                                dwell time). This is sometimes referred to as Sine-Dwell testing.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion p-3" id="accordionExample2">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne2">
                    <strong>Random</strong>
                </button>
            </h2>
            <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2"
                data-bs-parent="#accordionExample2">
                <div class="accordion-body">
                    <div class="image-vib-right">
                        <img style="max-width: 100%; height: auto;"
                            src="../../assets/images/VibTest/Sine_MTL_Sample_Page_6.png" alt="" />
                        <h3 style="font-weight: bold; line-height: 1.6;">In random vibration testing, the DUT is
                            constantly being excited by shaped vibration content
                            across a frequency range. These tests are often specified in terms of the power spectral
                            density
                            units of position, velocity, or acceleration as a function of
                            frequency. The most common for our clients is to specify the breakpoints in a table in units
                            of
                            g2/Hz at specific frequencies in Hz. While a table as described contains the information
                            needed
                            to define a random vibration test,
                            it is quite common to integrate the spectrum and also provide the gRMS value. This is
                            analogous
                            to the average of the acceleration imparted on the DUT across all frequencies. We often see
                            instantaneous accelerations several times
                            the gRMS level.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion p-3" id="accordionExample3">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne3" aria-expanded="false" aria-controls="collapseOne3">
                    <strong>Combined Environment</strong>
                </button>
            </h2>
            <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3"
                data-bs-parent="#accordionExample3">
                <div class="accordion-body">
                    <h3 style="font-weight: bold; line-height: 1.6;">
                        The random and sine test can also be superimposed resulting in mixed modes such as
                        sine-on-random. In addition, environmental conditions such as high or low temperature can be
                        combined with vibration for Highly Accelerated Stress Testing (HAST) or Highly
                        Accelerated Stress Screen (HASS). It is also possible to test DUTs operational with fluid or
                        electrical connections during vibration.
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion p-3" id="accordionExample4">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne4" aria-expanded="false" aria-controls="collapseOne4">
                    <strong>Equipment Operational Envelope</strong>
                </button>
            </h2>
            <div id="collapseOne4" class="accordion-collapse collapse" aria-labelledby="headingOne4"
                data-bs-parent="#accordionExample4">
                <div class="accordion-body">
                    <h3 style="font-weight: bold; line-height: 1.6;">
                        Our UD T-1000 shaker is bounded by several hard limits. For sine and random tests, displacement
                        is limited to 2 in. double-amplitude (peak-to-peak), velocity is limited to 105 in./sec.,
                        acceleration is limited to 180 g, and peak force to 18,000 lbf. The
                        drive and feedback frequencies are limited from 5 Hz to 2,000 Hz. Some of these limits reduce
                        depending on the mode of operation and other parameters. We have developed our own comprehensive
                        quote sheet incorporating these parameters
                        to ensure your test can run on our equipment.
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="image-vib-center">
        <img style="max-width: 100%; height: auto;" src="../../assets/images/VibTest/MTL_RandomQuoteSheet_Page_1.png"
            alt="" />
        <h3 style="font-weight: bold;">Please contact us to get custom quotes specific to your needs</h3><br>
        <img style="max-width: 100%; height: auto;" src="../../assets/images/VibTest/MTL_RandomQuoteSheet_Page_2.png"
            alt="" />
    </div>
    <div>
        <h3>
            <strong>How to Specify a Test</strong>
        </h3>
        <h3>
            When preparing for vibration testing, there are many parameters that need to be specified. While we are
            more than happy to provide information enabling your engineering team to specify the appropriate test, we
            list the most common items:
        </h3>
        <h3>
            <ol class="olSpecify" style="line-height: 1.6;">
                <li class="liSpecify">Standard: If you require testing against a particular standard, please specify it.
                    Remember, some standards can be tailored or used as a basis for custom testing. See related
                    <a href="standards">Standards</a>.</li>
                <li class="liSpecify">Test time: Specifies the duration your DUT must be under vibration. In vibration,
                    we have seen these range from seconds to more than 10 hours. For Swept-Sine tests, the sweep rate
                    must also be specified. </li>
                <li class="liSpecify">Test level: Fully define your vibration level needed. Typically, in units of g for
                    Swept-Sine, and g2/Hz for random. Sometimes a gRMS level is defined for random tests as a check.
                </li>
                <li class="liSpecify">Test frequency: Specify your minimum and maximum test frequency in Hz.</li>
                <li class="liSpecify">Axis: Specify the vibration axis of the part. Our shaker actuates in one dimension
                    at a time. Different axes may require different fixturing. When doing small vibration levels,
                    recognition of the superposition of Earth’s gravity on
                    the DUT may become important.</li>
                <li class="liSpecify">Fixturing: While we have the ability to offer fixturing to our shaker, you are
                    more than welcome to create your own. In vibration, stiffness and specific stiffness are often more
                    important than static strength. To help, we provide solid models of
                    our shaker head (round) and slip plate (roughly rectangular) as an [ePrt file] (free viewer
                    available <a href="https://www.edrawingsviewer.com/view-cad-files-free">here</a>) and as a [STEP file].
                    All interface by NC ½-13 fasteners. Many clients find that a 1 in.
                    thick aluminum plate with counterbores for ½-13 socket head cap screws provides a nice fixture.
                    Because the shaker head bolt pattern
                    is replicated on the slip plate, using the bolt hole pattern of the head on your fixture enables its
                    use on either providing greater flexibility.</li>
                <li class="liSpecify">Mass: It is very important to accurately specify both the DUT mass and all
                    fixturing mass, typically in lbm. Since our electro-dynamic shaker is force limited, the maximum
                    acceleration available is often limited by the test and fixture
                    mass.
                </li>
                <li class="liSpecify">Fixturing stiffness: It is important to know the fixture resonances as they couple
                    the shaker and the DUT and can actually amplify the vibration level. Resonances in the test
                    frequency band of high quality factor (Q) are especially
                    to be avoided and can result in extreme overtesting of the DUT.</li>
                <li class="liSpecify">Accelerometers: The default in many standards is to control the vibration level by
                    placing an accelerometer on the test fixture. This can be expanded by also placing witness
                    accelerometer on the DUT at specified locations. Multiple
                    accelerometers enable transfer function amplitudes, phases, and coherence to be gathered. Extremal
                    control is also possible. Number of accelerometers, positions, type (control or witness), and any
                    required transfer function information
                    must be specified.</li>
                <li class="liSpecify">Time series data: The default is to provide a graph at the end of testing with the
                    level vs. frequency data. Optionally, the accelerometer and drive data can be collected
                    independently as a time-series file enabling post processing
                    or correlation with your own electrical DUT signals. </li>
                <li class="liSpecify">Control Tolerance: The tolerance on the vibration. Often, for random tests, this
                    is expressed in terms of power dB. A common tolerance is +/- 3 dB. Sometimes tolerance is a function
                    of frequency. For example, +/- 3 dB below 1,000 Hz
                    and +/- 6 dB above 1,000 Hz. Tolerance must always be specified for the gRMS value (typical is +/-
                    10 % or more) since this is not a driving parameter.</li>
                <li class="liSpecify">Degrees-of-Freedom (Random Testing): The higher the DOF specified, the more
                    averaging of the spectral data. Sometimes this is specified in alternate formats such as number of
                    segments, etc. This is often specified in the standard.
                    We recommend not less than 120. </li>
                <li class="liSpecify">Frequency Resolution (Random Testing): The frequency resolution of the
                    acceleration power spectral density graph, the number of frequency lines, and maximum frequency are
                    related. </li>
                <li class="liSpecify">Pre / Post Sine Sweeps (Random Testing): Optionally specify any pre or post test
                    sine sweep. Sometimes this is specified for new DUTs or new fixtures. Often this is an exploratory
                    scan at a very low level (often less than 1 g) to check
                    for any unknown resonances. If pre and post sweeps are performed, any changes in resonant
                    frequencies or shapes may be an indication of a mechanical change within the DUT during the test.
                </li>
                <li class="liSpecify">Additional Plots: By default, we provide the last acceleration power spectral
                    density plot at the conclusion of the test. This documents the spectral amplitudes as a function of
                    frequency, tolerance bands and, for random tests, includes
                    the gRMS value. If necessary, plots can be provided at discreet intervals throughout the test in
                    addition to the final plot.</li>
            </ol>
        </h3>
    </div>

    <!-- <div #fixturetable id="fixturetable">
        <div style="padding-bottom: .5em; padding-top: 2em;">
            <h3><strong>Fixturing</strong></h3>
        </div>
        <div>
            <h3 style="line-height: 1.6;">Correct attachment of your DUT to our shaker is important. Some folks approach
                fixturing from merely a
                strength standpoint. In vibration, specific stiffness (specific modulus) is often more important than
                static strength. Both magnesium
                and aluminum usually work well. Best fixtures have their resonant frequencies above the test band. This
                may not always be possible. Having foreknowledge of the resonances will still help. Finite Element
                Analysis (FEA), or even simply
                taking a Fourier Transform (FFT) of an audio recording of the fixture being excited (rubber hammer) can
                provide insight.
            </h3>
        </div>
        <div style="padding-bottom: .5em; padding-top: 2em; line-height: 1.6;">
            <h3>To help, we provide the following solid models of our shaker head (round) and slip plate (roughly
                rectangular) as an [ePrt file] (free viewer available [here]) and as a [STEP file]. All interface by NC
                ½-13 fasteners. [Figure of isometric
                of file] </h3>
        </div>
        <div style="padding-bottom: .5em; padding-top: 2em; line-height: 1.6;">
            <h3>Many clients find that a 1 in. thick aluminum plate with counterbores for ½-13 socket head cap screws
                provides a nice fixture. Because the shaker head bolt pattern is replicated on the slip plate, using the
                bolt hole pattern of the head
                on your fixture enables its use on either providing greater flexibility.</h3>
        </div>
        <div style="padding-bottom: .5em; padding-top: 2em; line-height: 1.6;">
            <h3>Here are some generic fixture plates that you are free to download, modify, and have machined for your
                application.</h3>
            <div style="padding-top: 2em;">
                <table>
                    <tr>
                        <th>Fixture Plate</th>
                        <th>Nominal Stock Size</th>
                        <th>ePRT</th>
                        <th>STEP</th>
                        <th>Mass [lbm]</th>
                    </tr>
                    <tr>
                        <td>link to jpg</td>
                        <td>18 in. x 18 in. x 1.5 in. aluminum</td>
                        <td></td>
                        <td></td>
                        <td>42 lbm</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>18 in. x 18 in. x 1 in. aluminum</td>
                        <td></td>
                        <td></td>
                        <td>28 lbm.</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>12 in. x 12 in. x 1.5 in. aluminum</td>
                        <td></td>
                        <td></td>
                        <td>20 lbm.</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>12 in. x 12 in. x 1 in. aluminum</td>
                        <td></td>
                        <td></td>
                        <td>13 lbm.</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>10 in. diameter x 1.5 in. aluminum</td>
                        <td></td>
                        <td></td>
                        <td>11 lbm.</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>10 in. diameter x 1 in. aluminum</td>
                        <td></td>
                        <td></td>
                        <td>7 lbm.</td>
                    </tr>
                </table>
            </div>
        </div>
    </div> -->
    <div>
        <h3 style="padding-top: 2em;">
            <strong>Standards</strong>
        </h3>
        <h3>
            Since you know your product or specimen best, unfortunately, we are not able to recommend vibrational test
            standards. However, we are more than happy to work with you to tailor fixturing and talk about your
            vibration needs. We have experience testing
            to proprietary standards as well as some of the common standards below:
        </h3>
        <h3>
            <ul class="ulStandards">
                <li class="liStandards">MIL-STD-167-1A</li>
                <li class="liStandards">MIL-STD 202 Method 201 </li>
                <li class="liStandards">MIL-STD 202 Method 204 </li>
                <li class="liStandards">MIL-STD 202 Method 214 </li>
                <li class="liStandards">MIL-STD-810F Method 514.5 </li>
                <li class="liStandards">MIL-STD-810F Method 520.2 </li>
                <li class="liStandards">MIL-STD-810G Method 514.6 </li>
                <li class="liStandards">MIL-STD-810G Method 520.3 </li>
                <li class="liStandards">MIL-STD-810G Method 528 </li>
                <li class="liStandards">MIL-STD 810H Method 514.8 </li>
                <li class="liStandards">MIL-STD 810H Method 520.5 </li>
                <li class="liStandards">MIL-STD 810H Method 528.1 </li>
                <li class="liStandards">IEC 60529 RTCA DO-160G </li>
                <li class="liStandards">RTCA DO-160G</li>
            </ul>
        </h3>
        <h3 style="text-align: center;">See our <a href="standards">Standards Section</a> for more information on
            standards.</h3>
    </div>
</div>