<div class="category-home-description">
    <div>
        <h3 style="text-align: center;">
            <strong>Mechanical Testing</strong>
        </h3>
    </div>
    <div>
        <h3 style="padding-bottom: 2em; line-height: 1.6;">
            Mechanical testing typically includes measurements of the physical properties of materials. These properties include strength, stress, stiffness, hardness, toughness, resilience, load-through-time or fatigue.
        </h3>

        <div class="accordion p-3" id="accordionLoadTesting">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingLoadTesting">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLoadTesting" aria-expanded="false" aria-controls="collapseLoadTesting">
                        <strong>Load Testing</strong>
                    </button>
                </h3>
                <div id="collapseLoadTesting" class="accordion-collapse collapse" aria-labelledby="headingLoadTesting" data-bs-parent="#accordionLoadTesting">
                    <div class="accordion-body">
                        <h3 style="padding-bottom: 2em; line-height: 1.6;">
                            Load testing typically involves on of our load frames. These are often paired with an appropriate load cell, displacement measurement, and test fixture. Tests can be simple tension or compression tests using standard specimen coupons or fully customized
                            fixtures for specific test needs. Our most used load frames provide up to 110,000 lbf in compression or tension. In tension only, we have a load frame capable of 300,000 lbf and over 120 in. of travel. By adding an environmental
                            chamber onto our load frame, we have the capability of offering elevated and reduced temperature testing.
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion p-3" id="accordionHardnessTesting">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingHardnessTesting">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHardnessTesting" aria-expanded="false" aria-controls="collapseHardnessTesting">
                        <strong>Hardness Testing</strong>
                    </button>
                </h3>
                <div id="collapseHardnessTesting" class="accordion-collapse collapse" aria-labelledby="headingHardnessTesting" data-bs-parent="#accordionHardnessTesting">
                    <div class="accordion-body">
                        <h3 style="padding-bottom: 2em;">
                            Hardness testing is a measure of how easily a specimen is indented. This can help identify differing tempers or work hardening of an alloy.
                        </h3>
                        <h3 style="padding-bottom: 2em; line-height: 1.6;">
                            <ul>
                                <li><strong>Rockwell</strong><br>Rockwell hardness testing basically involves measuring the depth of the resulting indentation. Rockwell B and C scales are the most common for our clients. However, we have capabilities to test
                                    Rockwell A, B, C, D, E, F, G, H, and K. </li>
                                <li><strong>Vickers</strong><br>Vickers hardness testing differs from Rockwell in that the width of the indentation is used to determine hardness. Vickers is unique in that the hardness numbers, in theory, are continuous regardless
                                    of the applied load (although the load is always documented). The measurement process is slower with Vickers as the reading requires a metrology microscope.</li>
                                <li><strong>Micro-Indentation, Vickers, Knoop</strong><br>Micro-indentation hardness testing (sometimes called micro-hardness testing) involves small forces and small indentations. This enables surface hardness testing. If the
                                    specimen is sections, mounted in epoxy or phenolic, and polished, then micro-indentation hardness testing enables hardness measurements of the case through to the core.</li>
                                <li><strong>Superficial Rockwell</strong><br>Superficial Rockwell testing involves lower forces creating the indentation than standard Rockwell tests. We have capabilities of testing Superficial Rockwell 15N, 30N, 45N, 15T, 30T,
                                    45T, 15W, 30W, and 45W scales.</li>
                                <li><strong>Durometer</strong><br>Durometer testing often involves elastomeric, rubber-like materials. Our capabilities include Shore A and D scales. We can provide both maximum reading and 1 second readings. </li>
                            </ul>
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion p-3" id="accordionFatigueTesting">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingFatigueTesting">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFatigueTesting" aria-expanded="false" aria-controls="collapseFatigueTesting">
                        <strong>Fatigue Testing</strong>
                    </button>
                </h3>
                <div id="collapseFatigueTesting" class="accordion-collapse collapse" aria-labelledby="headingFatigueTesting" data-bs-parent="#accordionFatigueTesting">
                    <div class="accordion-body">
                        <h3 style="padding-bottom: 2em; line-height: 1.6;">
                            Fatigue testing enables rapid repetitive loadings to a specimen that would normally be tested in a load frame. Our fatigue frames can handle up to 50,000 lbf with a repetitive stroke length of several inches. We have thermal control capabilities by adding
                            an environmental chamber onto our fatigue testers.
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion p-3" id="accordionImpactTesting">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingImpactTesting">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseImpactTesting" aria-expanded="false" aria-controls="collapseImpactTesting">
                        <strong>Impact Testing</strong>
                    </button>
                </h3>
                <div id="collapseImpactTesting" class="accordion-collapse collapse" aria-labelledby="headingImpactTesting" data-bs-parent="#accordionImpactTesting">
                    <div class="accordion-body">
                        <h3 style="padding-bottom: 2em; line-height: 1.6;">
                            Impact testing (sometimes called fracture toughness) involves taking a notched specimen and rapidly breaking it. There are two main methods. Izod holds the sample on one end cantilevered while impacting the other. With the Charpy method, the specimen
                            is supported on both sides and impacted in the center. Our testers range from sub ft.-lbf. resolution to 240 ft.-lbf. capacities.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>