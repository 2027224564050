import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mechanical-testing',
  templateUrl: './mechanical-testing.component.html',
  styleUrls: ['./mechanical-testing.component.css']
})
export class MechanicalTestingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
