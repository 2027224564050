import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentRoute: string;
  
  goToVibrationTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/vibtest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToMechanicalTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/mechtest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToProductAssuranceTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/producttest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToElectricalTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/electricaltest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToFailureAnalysis($myParam: string = ''): void {
    const navigationDetails: string[] = ['/failuretest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToCryoTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/cryotest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToMetallurgicalTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/metatest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToEmiEmcTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/emiemctest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  
  goToPaintCoatingTesting($myParam: string = ''): void {
    const navigationDetails: string[] = ['/painttest'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }

  constructor(private router: Router){
    console.log(router.url);
    
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
  }

}
