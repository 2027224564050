import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from '../contact-service.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  public siteKey: any;
  public angForm: FormGroup;
  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      telephone: [''],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
    this.siteKey = "6LetJwUcAAAAANxH_6pGTJHSGBw85Fb81KxDF0Py";
  }

  constructor(
    private fb: FormBuilder,
    private contactUs: ContactService
  ) {
    this.createForm();
  }

  submitForm() {
    var name = this.angForm.get('name').value;
    var email = this.angForm.get('address').value;
    var telephone = this.angForm.get('telephone').value;
    var message = this.angForm.get('message').value;
    
    this.contactUs.sendEmail(name, email, telephone, message).subscribe(
      res => {
        alert('Success! We will contact you within 1-2 business days!')
        this.angForm.reset();
      },
      err => {
        alert('Success! We will contact you within 2 business days!')
        this.angForm.reset();
      });
  }
}
