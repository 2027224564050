import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shock-testing',
  templateUrl: './shock-testing.component.html',
  styleUrls: ['./shock-testing.component.css']
})
export class ShockTestingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
