import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-env-testing',
  templateUrl: './env-testing.component.html',
  styleUrls: ['./env-testing.component.css']
})
export class EnvTestingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
