<div class="category-home-description">
    <div>
        <h3 class="pb-4" style="text-align: center;">
            <strong>Shock Testing</strong>
        </h3>
    </div>
    <div>
        <h3 class="pb-4">
            Shock testing typically involves calibrated, repeatable, violent events. The resulting energy is then absorbed transferred through the test specimen or Device Under Test (DUT)
        </h3>
    </div>
    <div>
        <div class="accordion p-3" id="accordionClassical">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingClassical">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseClassical" aria-expanded="false" aria-controls="collapseClassical">
                        <strong>Classical Shock</strong>
                    </button>
                </h3>
                <div id="collapseClassical" class="accordion-collapse collapse" aria-labelledby="headingClassical" data-bs-parent="#accordionClassical">
                    <div class="accordion-body">
                        <h3 style="line-height: 1.6;">
                            We use our <a href="vibtest">[electro-dynamic shaker]</a> for classical shock. As these pulses are computer generated, we can support multiple waveforms including half-sine, pre-peak sawtooth, post-peak sawtooth, trapezoidal, and rectangular. Most
                            commonly, the desired waveform, peak acceleration (often in g), and duration (often in ms) are specified. Tolerances are often obtained from the parent specification. Note that the tolerances of classical shock have changed between
                            each revision of the typical MIL-STD-810: 810F 810G, and 810H.
                        </h3>
                        <h3>
                            Our shaker can handle slightly higher accelerations and velocities in shock than in vibration. The displacement limits do not change.
                        </h3>
                        <h3>Fixturing for classical shock must interface with our shaker <a href="vibtest">here</a></h3>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="accordion p-3" id="accordionShock">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingShock">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseShock" aria-expanded="false" aria-controls="collapseShock">
                        <strong>Shock Synthesis (SRS)</strong>
                    </button>
                </h3>
                <div id="collapseShock" class="accordion-collapse collapse" aria-labelledby="headingShock" data-bs-parent="#accordionShock">
                    <div class="accordion-body">
                        <h3 style="line-height: 1.6;">
                            The ability of our computer controlled electro-dynamic shaker enable multiple wavelets at discreet frequencies, amplitudes and phases to be built into a chain. This shock synthesis chain can excite the Device Under Test (DUT) in a way that is similar
                            to drop shock but more controllable. The test results are often plotted as the Shock Response Spectrum (SRS). The same vibration shaker limit applies in displacement with slightly higher levels obtainable in acceleration and velocity.
                        </h3>
                        <h3 style="line-height: 1.6;">
                            Resulting SRS maxi-max, primary +, or primary – plots can be provided for displacement, pseudo-velocity, or acceleration.
                        </h3>
                        <h3 style="line-height: 1.6;">Fixturing for shock synthesis must interface with our shaker <a href="vibtest">here</a></h3>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="accordion p-3" id="accordionDrop">
            <div class="accordion-item">
                <h3 class="accordion-header" id="headingDrop">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDrop" aria-expanded="false" aria-controls="collapseDrop">
                        <strong>Drop Shock</strong>
                    </button>
                </h3>
                <div id="collapseDrop" class="accordion-collapse collapse" aria-labelledby="headingDrop" data-bs-parent="#accordionDrop">
                    <div class="accordion-body">
                        <h3 style="line-height: 1.6;">
                            In drop shock testing, the Device Under Test (DUT) is typically fixed to a guided sled. This sled is raised to a predetermined height. Upon release the sled accelerates in earth’s gravitational field and impacts a programmer at the base. The programmer
                            consists of elastomeric pads, plastic cylinders, and / or a high-pressure nitrogen cylinder, are adjusted to provide the desired response. Accelerations of up to 600 g can be attained.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>