import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { VibrationTestingComponent } from './vibration-testing/vibration-testing.component';
import { MechanicalTestingComponent } from './mechanical-testing/mechanical-testing.component';
import { ShockTestingComponent } from './shock-testing/shock-testing.component';
import { EnvTestingComponent } from './env-testing/env-testing.component';

import { StandardsComponent } from './standards/standards.component';

import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'about', component: AboutComponent},
  { path: 'search-results', component: SearchResultsComponent},

  { path: 'vib-testing', component: VibrationTestingComponent},
  { path: 'shock-testing', component: ShockTestingComponent},
  { path: 'env-testing', component: EnvTestingComponent},
  { path: 'mech-testing', component: MechanicalTestingComponent},

  { path: 'standards', component: StandardsComponent},

  { path: 'contact-us', component: ContactUsComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
