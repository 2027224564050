<div class="category-home-description" style=" line-height: 1.6;">
    <h1>Click on a Standard to see our list:</h1>
    <ul>

        <li style="margin-top: 1em;">
            <span><a href="astm">ASTM - American Society for Testing and Materials</a><br>ASTM International, formerly
                known as American Society for Testing and Materials,
                is an international standards organization that develops and publishes voluntary consensus technical
                standards for a wide range of
                materials, products, systems, and services. Some 12,575 ASTM voluntary consensus standards operate
                globally. The
                organization's headquarters is in West Conshohocken, Pennsylvania, about 5 mi (8.0 km) northwest of
                Philadelphia.
            </span><br>
            <p style="width: 100%; text-align: right; font-size: 1vw;"><a href="https://astm.org" target="_blank">[Visit Them!]</a></p>
        </li>

        <li style="margin-top: 1em;">
            <span><a href="mil">MIL - Military Standard</a><br>A United States defense standard, often called a military
                standard, "MIL-STD",
                "MIL-SPEC", or (informally) "MilSpecs", is used to help achieve standardization objectives by the U.S.
                Department of Defense.
                Standardization is beneficial in achieving interoperability, ensuring products meet certain
                requirements,
                commonality, reliability, total cost of ownership, compatibility with logistics systems, and similar
                defense-related objectives.
                Defense standards are also used by other non-defense government organizations, technical organizations,
                and
                industry. This article discusses definitions, history, and usage of defense standards. Related
                documents, such
                as defense handbooks and defense specifications, are also addressed.
            </span><br>
            <p style="width: 100%; text-align: right; font-size: 1vw;"><a href="https://www.dsp.dla.mil" target="_blank">[Visit Them!]</a></p>
        </li>

        <li style="margin-top: 1em;">
            <span><a href="sae">SAE - Society of Automotive Engineers</a><br>Standards from SAE International are used to advance mobility engineering
                throughout
                the world. The SAE Technical Standards Development Program is now-and has been for nearly a
                century-among the
                organization's primary provisions to those mobility industries it serves: aerospace, automotive, and
                commercial
                vehicle. Today's SAE standards product line includes almost 10,000 documents created through consensus
                standards
                development by more than 240 SAE Technical Committees with 450+ subcommittees and task groups. These
                works are
                authorized, revised, and maintained by the volunteer efforts of more than 9,000 engineers, and other
                qualified
                professionals from around the world. Additionally, SAE has 60 US Technical Advisory Group (USTAG's) to
                ISO
                Committees.
            </span><br>
            <p style="width: 100%; text-align: right; font-size: 1vw;"><a href="https://www.sae.org" target="_blank">[Visit Them!]</a></p>
        </li>

        <li style="margin-top: 1em;">
            <span><a href="en">EN - European Standard</a><br>European Standards (EN) are technical standards which have
                been ratified by one of the three European standards organizations: European Committee for
                Standardization
                (CEN), European Committee for Electrotechnical Standardization (CENELEC), or European Telecommunications
                Standards Institute (ETSI). All ENs are designed and created by all interested parties through a
                transparent,
                open, and consensual process.
                European Standards are a key component of the Single European Market. They are crucial in facilitating
                trade and
                have high visibility among manufacturers inside and outside the European territory. A standard
                represents a
                model specification, a technical solution against which a market can trade.
                European Standards must be transposed into a national standard in all EU member states. This guarantees
                that a
                manufacturer has easier access to the market of all these European countries when applying European
                Standards.
                Member countries must also withdraw any conflicting national standard: the EN supersedes any national
                standard.
            </span><br>
            <p style="width: 100%; text-align: right; font-size: 1vw;"><a href="https://en.wikipedia.org/wiki/European_Standard" target="_blank">[Visit Them!]</a></p>
        </li>

        <li style="margin-top: 1em;">
            <span><a href="iec">IEC - International Electrotechnical Commission</a><br>The International
                Electrotechnical
                Commission (IEC; in French: Commission électrotechnique internationale) is an international standards
                organization that prepares and publishes international standards for all electrical, electronic and
                related technologies – collectively known as "electrotechnology". IEC standards cover a vast range of
                technologies from power generation, transmission and distribution to home appliances and office
                equipment,
                semiconductors, fibre optics, batteries, solar energy, nanotechnology and marine energy as well as many
                others.
                The IEC also manages four global conformity assessment systems that certify whether equipment, system or
                components conform to its international standards.
                All electrotechnologies are covered by IEC Standards, including energy production and distribution,
                electronics,
                magnetics and electromagnetics, electroacoustics, multimedia, telecommunication and medical technology,
                as well
                as associated general disciplines such as terminology and symbols, electromagnetic compatibility,
                measurement
                and performance, dependability, design and development, safety and the environment.
            </span><br>
            <p style="width: 100%; text-align: right; font-size: 1vw;"><a href="https://www.iec.ch/homepage" target="_blank">[Visit Them!]</a></p>
        </li>

        <li style="margin-top: 1em;">
            <span><a href="iso">ISO - International Organization for Standardization</a><br>The International
                Organization for
                Standardization (ISO; /ˈaɪɛsoʊ/) is an international standard-setting body composed of representatives
                from
                various national standards organizations.
                Founded on 23 February 1947, the organization develops and publishes worldwide technical, industrial,
                and
                commercial standards. It is headquartered in Geneva, Switzerland and works in 165 countries.
                It was one of the first organizations granted general consultative status with the United Nations
                Economic and
                Social Council.
            </span><br>
            <p style="width: 100%; text-align: right; font-size: 1vw;"><a href="https://www.iso.org/home.html" target="_blank">[Visit Them!]</a></p>
        </li>
    </ul>

</div>