import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendEmail(name, email, telephone, message) {
    const uri = 'https://loczpccqpe.execute-api.us-west-1.amazonaws.com/default/martinTestingSendEmailForm';
    const obj = `
        Name: ${name}\n\n
        Email: ${email}\n\n
        Telephone: ${telephone}\n\n
        Message: ${message}
      `;

    return this.http.post(uri, obj, { responseType: 'text' });
  }
}