<div class="category-home-description">
    <h3 style="line-height: 1.6;"><b>Martin Testing Laboratories</b> was incorporated in the State of California in Jan. 2000. Our 17,400 sq. ft. laboratory was “captured in place” by several employees after the closing of McClellan AFB. The equipment inventory now exceeds $6.6M as
        we round out our equipment list to better match commercial testing needs. We started with only 8 commercial customers and four employees however in Jan. 2004 our customer list exceeds 200 companies and we have 27 employees, although most are on-call
        (contract) employees who are retired former AF people. We are continuously remodeling and upgrading the interior and have a major “facelift” scheduled for the exterior of the building in early 2005.
    </h3><br>

    <h3 style="line-height: 1.6;"><b>In 2001</b> the corporate name was changed to Materials Technology Laboratories, Inc. to better reflect the diversity of Services offered by the company. Martin Testing Laboratories continues in operation as a division of the company. We are growing
        with the Sacramento region and tailoring our service portfolio to match the needs of this growing high-technology area. Late in 2003 we acquired a GSA schedule contract with the help of the FTC. We feel the aerospace experience of our workforce
        matches well against the needs of many government agencies and we will be aggressively marketing our services through this contract vehicle. We hope to add a State of California Schedule contract in 2004 so that we may offer these same services
        to CA State Agencies.
    </h3><br><br>
</div>
<div class="category-home-description">
    <div class="mechanical-testing-home">
        <div class="mechanical-testing-home-div">
            <h3 style="line-height: 1.6;">
                <p style="font-weight: bold; text-align: center;">McClellan Park Airfield</p>
                10,600 ft. Long, 150 ft. Wide Runway - Full Instrument Approach McClellan Park runway opened up as a public airfield October 1, 2000. This is a core amenity which enables direct convenient for tenants, specialty services and general aviation use.
                <br><br>
                <p><b>Hours of Operation:</b> McClellan Airfield Operation facility is attended, Monday - Friday 08:00 - 16:30, (916) 643-5611. After hours (916) 286-5000 weekends and holidays on 24hr. Notice.</p>
                <br><br>
                <p><b>Latitude 38' 40'03" N. Longitude 121' 24' 02" W.</b></p>
                <br><br>
                <p><b>Traffic Advisory:</b> McClellan Park Airfield is an uncontrolled airfield (No Tower), in Class E Airspace. The Airport is approved for day and night use. Pilots of aircraft arriving, departing, or operating within 10 nautical miles
                    of McClellan Park Airfield should make a standard Common Traffic Advisory Frequency broadcast on 122.85 MHz.</p>
                <p><b>Sacramento Approach:</b> 119.1 Rancho Murrieta FSS: 122.05 / 122.3 </p>
                <p><b>Physical Features:</b> The physical length of the runway is 10,600 feet, 150 feet wide, has non-standard lights depicting that it is 200 feet wide. The south safety area is 1,270 feet long. The north safety area is 1,125 feet long.
                    Lighted runway distance markers are located 75 feet from the edge of the pavement, depicting distance remaining in thousands of feet.</p>
                <p>ILS RWY 16 VOR/DME RWY 16/34 Designated Traffic pattern is as follows: Left Traffic RWY 34 Right Traffic RWY 16 Calm Wind RWY 16 1000 Feet AGL</p>
            </h3>
        </div>
        <div class="mechanical-testing-home-div">
            <div>
                <h3 style="line-height: 1.6;">
                    <p style="font-weight: bold; text-align: center;">McClellan Aviation Museum</p>

                    <p>Our shipping address and phone/fax info is at the bottom of every page.</p>

                    <p>For information or to e-mail an RFQ: <a routerLink="/contact-us">Contact Us!</a>
                    </p>

                    <p>Visitors will find us in Sacramento, CA. On most maps we are still listed as McClellan AFB. Enter the Peacekeeper Gate off of Watt Ave. Just inside the gate is a stoplight, which is Arnold (Like our Governor) Ave. Turn left onto Arnold
                        and proceed to the first stop sign. Go straight through the stop sign (after stopping) and we are the first building on the left Want to fly in?: Call us when you land and we'll pick you up!</p>

                    <p>McCLELLAN PARK AIRPORT INFORMATION 3028 Peacekeeper Way McClellan Park, CA 95652 (916) 643-5611 / Fax (916) 643-5616</p>
                </h3>
            </div>
        </div>
    </div>
</div>