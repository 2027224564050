import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MDBBootstrapModule, WavesModule, InputsModule, ButtonsModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogModule } from '@angular/material/dialog';
import { GoogleMapsModule } from '@angular/google-maps'
import { AppRoutingModule } from './app-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { StandardsComponent } from './standards/standards.component';
import { VibrationTestingComponent } from './vibration-testing/vibration-testing.component';
import { MechanicalTestingComponent } from './mechanical-testing/mechanical-testing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ShockTestingComponent } from './shock-testing/shock-testing.component';
import { EnvTestingComponent } from './env-testing/env-testing.component';
import { SearchResultsComponent } from './search-results/search-results.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    StandardsComponent,
    VibrationTestingComponent,
    MechanicalTestingComponent,
    ContactUsComponent,
    ShockTestingComponent,
    EnvTestingComponent,
    SearchResultsComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    WavesModule,
    MatDialogModule,
    FontAwesomeModule,
    InputsModule, 
    ButtonsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    GoogleMapsModule,
    NgxCaptchaModule, // captcha module for angular
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { 

  
}
